@import url('https://use.typekit.net/gyw8avh.css');

$background: #fff;
$primary: #00f;
$secondary: navy;
$accent: #ddedff;

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: synthese, Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $background;
}

.app {
    padding: 40px;
    height: 100vh;
    position: relative;

    @media (max-width: 720px) {
        padding: 24px;
    }

    @media (max-width: 580px) {
        height: auto;
        padding: 16px;
    }
}

// APP CONTAINER
.container {
    border: 4px solid $primary;
    display: flex;
    height: 100%;
    min-height: 520px;
    position: relative;

    @media (max-width: 580px) {
        border: 2px solid $primary;
        flex-direction: column;
        min-height: unset;
    }
}

// RESULTS CONTAINER
.results {
    border-right: 4px solid $primary;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    @media (max-width: 580px) {
        border-bottom: 2px solid $primary;
        border: 0;
    }
}

// CATEGORY BOX
.results--category {
    color: $primary;
    display: flex;
    flex: 1;
    padding: 40px;
    position: relative;
    text-decoration: none;

    @media (max-width: 580px) {
        padding: 24px;
    }

    &.genre {
        border-bottom: 2px solid $primary;

        @media (max-width: 580px) {
            border-bottom: 1px solid $primary;
        }
    
    }
    &.instrument {
        border-top: 2px solid $primary;

        @media (max-width: 580px) {
            border-top: 1px solid $primary;
            border-bottom: 2px solid $primary;
        }
    }

    .label {
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        left: 24px;
        line-height: 30px;
        position: absolute;
        top: 24px;

        @media (max-width: 580px) {
            font-size: 16px;
            left: 16px;
            line-height: 24px;
            top: 16px;
        }
    }

    .value {
        align-self: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        justify-self: center;
        line-height: 48px;
        margin: 0;
        padding: 80px 0;
        text-align: center;
        width: 100%;

        @media (max-width: 580px) {
            font-size: 24px;
            line-height: 36px;
            padding: 48px 0;
        }

        &--text {
            position: relative;
            display: inline-block;

            &:after {
                background-color: $primary;
                bottom: -2px;
                content: '';
                height: 0%;
                left: 0;
                position: absolute;
                right: 0;
                transition: height 300ms ease;
                width: 100%;
            }
        }
    }
}

// LINK
.results--link {
    align-self: center;
    background-color: $accent;
    color: $primary;
    display: flex;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    height: 100%;
    line-height: 30px;
    padding: 40px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 300ms ease, color 300ms ease;
    width: fit-content;

    @media (max-width: 580px) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        padding: 24px;
    }

    &:hover {
        color: $secondary;
        background-color: darken($accent, 5);
    }

    span {
        align-self: center;
        display: block;
        width: 100%;
    }
}

// LOADING ANIMATIONS
.container.loading {
    * {
        pointer-events: none;
    }

    .value--text:after {
        height: 100%;
    }

    .results--link {
        background-color: $background;
        color: $background;
    }
}
